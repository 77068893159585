import React from 'react';
import { composeClassName } from '../../../../../../utils/util_helpers';
import { useHelpOrErrorMessage } from './hooks/use-help-or-error-message';
import { TextInputHelpOrErrorMessageProps } from './types';

const TextInputHelpOrErrorMessage: React.FC<TextInputHelpOrErrorMessageProps> =
  ({
    errorMessage,
    showErrorMessage,
    showHelpMessage,
    helpMessage,
    invalid,
  }) => {
    const { showHelpOrErrorMessage, helpOrErrorMessage } =
      useHelpOrErrorMessage({
        errorMessage,
        showErrorMessage,
        showHelpMessage,
        helpMessage,
        invalid,
      });

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {showHelpOrErrorMessage && (
          <span
            className={composeClassName(
              'w-full break-words pt-1 text-footnote1 text-text-tertiary',
              invalid && 'text-text-failed'
            )}
          >
            {helpOrErrorMessage}
          </span>
        )}
      </>
    );
  };

export { TextInputHelpOrErrorMessage };
