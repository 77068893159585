import { AxiosRequestConfig } from 'axios';
import { axiosLib } from '@/lib/axios';
import {
  getColocApiCallProperties,
} from '..';
import { COLOC_APP_API_HOST } from '@/config';


const getColocTenantApiCallProperties = (): AxiosRequestConfig => {
  return {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}/api/v1/tenant`,
  };
};

export type UpdateCurrentContractAvailabilityDateParams = { availabilityDate: string };

// export type UpdateRoomAvailabilityResponse = RoomAssignment;

export const updateCurrentContractAvailabilityDate = (
  params: UpdateCurrentContractAvailabilityDateParams
) => {
  return axiosLib.patch(
    `/current_contract/room_availability`,
    {},
    {
      ...getColocTenantApiCallProperties(),
      params: { ...params },
    }
  );
};
