import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { composeClassName } from '@/utils/util_helpers';

const Testimonial = () => {
  const { t } = useTranslation();

  return (
    <div
      className={composeClassName(
        'flex flex-col-reverse items-center gap-4 rounded bg-Q50 p-3',
        'sm:flex-row sm:p-5'
      )}
    >
      <div className="flex flex-col items-center justify-center text-center">
        <Avatar
          firstName="Christophe"
          lastName="Vercarre"
          size="xxl"
          src={require(`@assets/images/marketplace/about-us/team/christophe.webp`)}
        />
        <span className="mt-2 text-lead2 font-medium text-Q400">
          Christophe Vercarre
        </span>
        <span className="text-body text-Q100">Co-Founder & CEO</span>
      </div>
      <div
        className={composeClassName(
          'inline-block h-[1px] w-full bg-Q75',
          'sm:h-[12rem] sm:w-[2px]'
        )}
      />
      <span
        className={composeClassName('text-body text-Q300', 'sm:text-lead2')}
      >
        {t('js.features.marketplace.about_us.section2.testimonial')}
      </span>
    </div>
  );
};

export { Testimonial };
