import { BaseIconWithAdjustableStrokeProps } from '../types';

const socialIconNames = [
  'apple',
  'discord',
  'facebook',
  'google',
  'instagram',
  'linkedin',
  'signal',
  'snapchat',
  'telegram',
  'pinterest',
  'tiktok',
  'twitter',
  'whatsapp',
  'youtube',
] as const;

export type SocialIconName = typeof socialIconNames[number];

export const isSocialIconName = (
  iconName: unknown
): iconName is SocialIconName => {
  // @ts-ignore
  return typeof iconName === 'string' && socialIconNames.includes(iconName);
};

type SocialIconVariants = 'black-white' | 'original';

export type SocialIconNameMappingProtocol = {
  [key in SocialIconVariants]: string;
};

export type SocialIconProps = BaseIconWithAdjustableStrokeProps & {
  /**
   * The name of the icon.
   */
  name: SocialIconName;
  /**
   * Variant of social icon.
   *
   * Options: black-white or original. Defaults to black-white
   */
  variant?: SocialIconVariants;
};
