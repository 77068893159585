import React from 'react';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { useAuthContext } from '@/hooks/use-auth';

const CurrentUserInfo = () => {
  const { user } = useAuthContext()!;

  return (
    <div className="flex items-center gap-1">
      <Avatar size="m" src={user?.avatar} type="person" />
      <div className="font-semibold">{user?.name}</div>
    </div>
  );
};

export { CurrentUserInfo };
