import { AxiosRequestConfig } from 'axios';
import { axiosLib } from '@/lib/axios';
import {
  ColocAPIDateRangeParams,
  ColocAPIPaginationParams,
  ColocAPIPaginationResponse,
  ColocAPISortDirectionResponse,
  ColocAPISortParams,
  getColocApiCallProperties,
} from '..';
import {
  HouseRentStatusFilter,
  HouseRentSummary,
  Rent,
  RentReport,
  RentReportStatus,
  RentReportVersion,
  RentStatus,
  RentTransaction,
} from '@/features/admin/rents/types';
import { COLOC_APP_API_HOST } from '@/config';
import { BankAccount, Transaction } from '@/features/admin/transactions/types';
import { House, RentOfContract, Room, TenantOfRoom } from '@/types/admin';
import {
  PayOutOrder,
  Payout,
  PayoutPayment,
  PayoutStatus,
} from '@/features/admin/payouts/types';
import {
  RoomAssignment,
  RoomAssignmentStatus,
} from '@/features/admin/contracts/types';
import {
  DiscussionThreadMessage,
  DiscussionThreadType,
} from '@/features/shared/discussion-threads/types';
import { NotificationType } from '@/features/shared/notifications/types';

const getColocAdminApiCallProperties = (): AxiosRequestConfig => {
  return {
    ...getColocApiCallProperties(),
    baseURL: `${COLOC_APP_API_HOST}/api/v1/admin`,
  };
};

// Rents
type GetRentsSummaryHousesParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams & {
    name?: string;
    status?: HouseRentStatusFilter;
    query?: string;
  };

export type GetRentsSummaryHousesResponse = ColocAPIPaginationResponse & {
  data: Array<HouseRentSummary>;
};

export const getRentsSummaryHouses = (params: GetRentsSummaryHousesParams) => {
  return axiosLib.get<GetRentsSummaryHousesResponse>(`/rents/houses`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

export const rentListSortableIds = [
  'tenant_name',
  'house_name',
  'due_on',
  'net_amount',
  'charges_amount',
  'discount_amount',
  'paid_amount',
  'unpaid_amount',
  'status',
] as const;

export type RentsListSortType = typeof rentListSortableIds[number];

type GetRentsListParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams &
  ColocAPISortParams & {
    name?: string;
    status?: RentStatus;
    query?: string;
    house_id?: string;
    tenant_id?: number;
    include_summary?: boolean;
    sort?: RentsListSortType;
  };

export type GetRentsListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<Rent>;
    sort: RentsListSortType;
  };

export const getRentsList = (params: GetRentsListParams) => {
  return axiosLib.get<GetRentsListResponse>(`/rents`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

type GetRentParams = {
  id: string;
};

export type GetRentResponse = Rent;

export const getRent = (params: GetRentParams) => {
  return axiosLib.get<GetRentResponse>(`/rents/${params.id}`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

type GetRentsCSVParams =
  | GetRentsListParams
  | ({
      ids: string;
      sort?: RentsListSortType;
    } & ColocAPIPaginationParams);

export type GetRentsCSVResponse = any;

export const getRentsCSV = (params: GetRentsCSVParams) => {
  return axiosLib.get<GetRentsCSVResponse>(`/rents/export_csv`, {
    ...getColocAdminApiCallProperties(),
    responseType: 'blob',
    params: { ...params },
  });
};

export type UpdateRentAction =
  | 'first_reminder'
  | 'second_reminder'
  | 'delete'
  | 'collected'
  | 'uncollected'
  | 'regenerate'
  | 'resolved';

export type UpdateRentsParams = {
  status: UpdateRentAction;
  rent_ids: Array<string>;
};

export type UpdateRentsResponse = {
  data: Array<Rent>;
};

export const updateRents = (params: UpdateRentsParams) => {
  return axiosLib.patch<UpdateRentsResponse>(`/rents/bulk_update`, [], {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

export type getRentTransactionsParams = {
  rent_id: string;
};

export type getRentTransactionsResponse = Array<RentTransaction>;

export const getRentTransactions = (params: getRentTransactionsParams) => {
  return axiosLib.get<getRentTransactionsResponse>(`/rent_transactions`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

export type AddRentTransactionParams = {
  rent_id: string;
};

export type AddRentTransactionRequestBody = {
  transaction: {
    date: string;
    amount: number;
  };
};

export type AddRentTransactionResponse = {
  rent: Rent;
  transaction: RentTransaction;
};

export const addRentTransaction = (
  params: AddRentTransactionParams,
  requestBody: AddRentTransactionRequestBody
) => {
  return axiosLib.post<AddRentTransactionResponse>(
    `/rent_transactions`,
    requestBody,
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    }
  );
};

export type DeleteRentTransactionParams = {
  id: number;
};

export type DeleteRentTransactionResponse = {
  rent: Rent;
};

export const deleteRentTransaction = (params: DeleteRentTransactionParams) => {
  return axiosLib.delete<DeleteRentTransactionResponse>(
    `/rent_transactions/${params.id}`,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export const rentReportListSortableIds = [
  'house_name',
  'date',
  'status',
] as const;

export type RentReportListSortType = typeof rentReportListSortableIds[number];

type GetRentReportListParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams &
  ColocAPISortParams & {
    updated_since?: Date | undefined;
    status?: RentReportStatus;
    house_id?: string;
    sort?: RentReportListSortType;
  };

export type GetRentReportListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<RentReport>;
    sort: RentReportListSortType;
  };

export const getRentReportList = (params: GetRentReportListParams) => {
  return axiosLib.get<GetRentReportListResponse>(`/rent_reports`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

export type GetRentReportVersionsParams = {
  report_id: string;
};

export type GetRentReportVersionsResponse = {
  data: Array<RentReportVersion>;
};

export const getRentReportVersions = (params: GetRentReportVersionsParams) => {
  return axiosLib.get<GetRentReportVersionsResponse>(
    `/rent_reports/${params.report_id}/versions`,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type GenerateRentReportParams = {
  house_ids: Array<string>;
  date: Date;
};

export type GenerateRentReportResponse = {
  data: Array<RentReport>;
};

export const generateRentReports = (params: GenerateRentReportParams) => {
  return axiosLib.post<GenerateRentReportResponse>(
    `/rent_reports/generate`,
    params,
    {
      ...getColocAdminApiCallProperties(),
      timeout: 20000,
    }
  );
};

// Transactions
export const transactionsListSortableIds = ['date', 'house', 'tenant'] as const;

export type TransactionListStatusFilterType = 'match' | 'unmatched' | 'ignored';

export type TransactionsListSortType =
  typeof transactionsListSortableIds[number];

type GetTransactionsListParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
    status?: TransactionListStatusFilterType;
    house_id?: number;
    bank_account_id?: string;
    tenant_id?: number;
    sort?: TransactionsListSortType;
  };

export type TransactionSummary = {
  transactions_count: number;
  last_update: Date;
};

export type GetTransactionsListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<Transaction>;
    sort: TransactionsListSortType;
    summary: TransactionSummary;
  };

export const getTransactionsList = (params: GetTransactionsListParams) => {
  return axiosLib.get<GetTransactionsListResponse>(`/transactions`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

type LinkTransactionWithRentParams = {
  transaction_id: string;
  rent_id: number;
};

type LinkTransactionWithRentResponse = Transaction;

export const linkTransactionWithRent = (
  params: LinkTransactionWithRentParams
) => {
  return axiosLib.patch<LinkTransactionWithRentResponse>(
    `/transactions/${params.transaction_id}/link_rent`,
    {},
    {
      ...getColocAdminApiCallProperties(),
      params: { rent_id: params.rent_id },
    }
  );
};

type IgnoreTransactionParams = {
  transaction_id: string;
};

type IgnoreTransactionResponse = Transaction;

export const ignoreTransaction = (params: IgnoreTransactionParams) => {
  return axiosLib.patch<IgnoreTransactionResponse>(
    `/transactions/${params.transaction_id}/ignore`,
    {},
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

// Accounts
export const bankAccountsListSortableIds = ['account_holder_name'] as const;

export type BankAccountListSortType =
  typeof bankAccountsListSortableIds[number];

type GetBankAccountsListParams = ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
  };

export type GetBankAccountsListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<BankAccount>;
    sort: BankAccountListSortType;
  };

export const getBankAccountsList = (params: GetBankAccountsListParams) => {
  return axiosLib.get<GetBankAccountsListResponse>(`/bank_accounts`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

// Houses
export const housesListListSortableIds = [
  'id',
  'name',
  'address',
  'city_name',
  'region_name',
  'owner_name',
  'rooms_available',
  'rooms_count',
  'status',
] as const;

export type HouseListSortType = typeof housesListListSortableIds[number];

type GetHouseListParams = ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
    published?: boolean;
    property_manager_id?: number;
    owner_id?: number;
    has_rooms_available?: boolean;
    region_id?: number;
    city_id?: number;
    sort?: HouseListSortType;
  };

export type GetHouseListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<House>;
    sort: HouseListSortType;
  };

export const getHouseList = (params: GetHouseListParams) => {
  return axiosLib.get<GetHouseListResponse>(`/houses`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

// Cities
export const citiesListSortableIds = ['name'] as const;

export type CityListSortType = typeof citiesListSortableIds[number];

type GetCityListParams = ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
    sort?: CityListSortType;
  };

export type GetCityListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<{ id: number; name: string }>;
    sort: CityListSortType;
  };

export const getCityList = (params: GetCityListParams) => {
  return axiosLib.get<GetCityListResponse>(`/cities`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

// Rooms
export const roomsListListSortableIds = ['name', 'availability_date'] as const;

export type RoomListSortType = typeof roomsListListSortableIds[number];

type GetRoomsListParams = ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
    house_id?: number;
    status?: 'available' | 'unavailable';
    include_amenities?: boolean;
  };

export type GetRoomListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<Room>;
    sort: RoomListSortType;
  };

export const getRoomList = (params: GetRoomsListParams) => {
  return axiosLib.get<GetRoomListResponse>(`/rooms`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

type GetTenantsForRoomParams = {
  room_id: number;
};

export type GetTenantsForRoomResponse = {
  data: Array<TenantOfRoom>;
};

export const getTenantsForRoom = (params: GetTenantsForRoomParams) => {
  return axiosLib.get<GetTenantsForRoomResponse>(
    `/transactions/contracts_for_room`,
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    }
  );
};

type GetRentForContractParams = {
  contract_id: number;
};

export type GetRentForContractResponse = {
  data: Array<RentOfContract>;
};

export const getRentsForContract = (params: GetRentForContractParams) => {
  return axiosLib.get<GetRentForContractResponse>(
    `/transactions/rents_for_contract`,
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    }
  );
};

export type GetPayoutCheckoutDetails = {
  data: Array<Payout>;
};

// Payouts
type GetPayoutSummaryParams = {
  rent_ids: string;
};

export type GetPayoutSummaryResponse = {
  data: Array<Payout>;
};

export const getPayoutPreviewSummary = (params: GetPayoutSummaryParams) => {
  return axiosLib.get<GetPayoutSummaryResponse>(`/payouts/preview`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

export const payoutsSortableIds = [
  'created_at',
  'house_name',
  'amount',
  'status',
] as const;

export type PayoutsSortType = typeof payoutsSortableIds[number];

type GetPayoutsParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams &
  ColocAPISortParams & {
    status?: PayoutStatus;
    house_id?: string;
    query?: string;
    sort?: PayoutsSortType;
  };

export type GetPayoutsResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<Payout>;
    sort: PayoutsSortType;
  };

export const getPayouts = (params: GetPayoutsParams) => {
  return axiosLib.get<GetPayoutsResponse>(`/payouts`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
  });
};

type CreatePayoutBody = {
  redirect_uri: string;
  items: Array<{ rent_id: number }>;
};

export type CreatePayoutResponse = {
  data: PayOutOrder;
};

export const createPayout = (requestBody: CreatePayoutBody) => {
  return axiosLib.post<CreatePayoutResponse>(`/payouts`, requestBody, {
    ...getColocAdminApiCallProperties(),
  });
};

export type GetPayoutPaymentParams = {
  id: string;
  force_sync?: boolean;
};

export type GetPayoutPaymentResponse = {
  payment: PayoutPayment & {
    payouts: Array<Payout>;
  };
};

export const getPayoutPayment = (params: GetPayoutPaymentParams) => {
  return axiosLib.get<GetPayoutPaymentResponse>(`/payments/${params.id}`, {
    ...getColocAdminApiCallProperties(),
    params: { force_sync: params.force_sync ?? false },
  });
};

// Contracts
export const roomAssignmentsListSortableIds = [
  'urgency',
  'availability_date',
] as const;

export type RoomAssignmentListSortType =
  typeof roomAssignmentsListSortableIds[number];

type GetRoomAssignmentsListParams = ColocAPIDateRangeParams &
  ColocAPIPaginationParams &
  ColocAPISortParams & {
    query?: string;
    status?: RoomAssignmentStatus;
    city_id?: string;
    house_id?: string;
    tenant_id?: number;
    sort?: RoomAssignmentListSortType;
  };

export type GetRoomAssignmentsListResponse = ColocAPIPaginationResponse &
  ColocAPISortDirectionResponse & {
    data: Array<RoomAssignment>;
    summary: {
      room_changes_count: number;
      rooms_available_count: number;
    };
    sort: RoomAssignmentListSortType;
  };

export const getRoomAssignmentList = (params: GetRoomAssignmentsListParams) => {
  return axiosLib.get<GetRoomAssignmentsListResponse>(`/room_assignments`, {
    ...getColocAdminApiCallProperties(),
    params: { ...params },
    timeout: 50000,
  });
};

export type GetRoomAssignmentParams = {
  id: number;
};

export type GetRoomAssignmentResponse = RoomAssignment;

export const getRoomAssignment = (params: GetRoomAssignmentParams) => {
  return axiosLib.get<GetRoomAssignmentResponse>(
    `/room_assignments/${params.id}`,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type UpdateRoomAvailabilityParams = { id: number; date?: string };

export type UpdateRoomAvailabilityResponse = RoomAssignment;

export const updateRoomAvailability = (
  params: UpdateRoomAvailabilityParams
) => {
  return axiosLib.patch(
    `/room_assignments/${params.id}/update_room_availability`,
    {},
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    }
  );
};

export type UpdateMoveInParams = { id: number; date?: string };

export const updateMoveIn = (params: UpdateMoveInParams) => {
  return axiosLib.patch(
    `/contracts/${params.id}`,
    {},
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    }
  );
};

// Discussion Threads
export type GetDiscussionThreadParams = {
  id: number;
};

export type GetDiscussionThreadResponse = {
  messages: Array<DiscussionThreadMessage>;
};

export const getDiscussionThreadById = (params: GetDiscussionThreadParams) => {
  return axiosLib.get<GetDiscussionThreadResponse>(
    `/discussion_threads/${params.id}`,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type CreateDiscussionThreadMessageParams = {
  id: number;
};

export type CreateDiscussionThreadMessageRequestBody = {
  content: string;
  mention_ids: Array<{ id: number }>;
};

export type CreateDiscussionThreadMessageResponse = {
  discussion_message: DiscussionThreadMessage;
};

export const createDiscussionThreadMessage = (
  params: CreateDiscussionThreadMessageParams,
  requestBody: CreateDiscussionThreadMessageRequestBody
) => {
  return axiosLib.post<CreateDiscussionThreadMessageResponse>(
    `/discussion_threads/${params.id}/messages`,
    {
      message: {
        content: requestBody.content,
        mention_ids: requestBody.mention_ids,
      },
    },
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type CreateDiscussionThreadParams = {};

export type CreateDiscussionThreadRequestBody = {
  discussable_id: number;
  discussable_type: DiscussionThreadType;
  message: {
    content: string;
    mention_ids: Array<{ id: number }>;
  };
};

export type CreateDiscussionThreadResponse = {
  discussion_thread: { id: number; messages: Array<DiscussionThreadMessage> };
};

export const createDiscussionThread = (
  requestBody: CreateDiscussionThreadRequestBody
) => {
  return axiosLib.post<CreateDiscussionThreadResponse>(
    `/discussion_threads`,
    requestBody,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type DeleteDiscussionThreadMessageParams = {
  id: number;
};

export type DeleteDiscussionThreadMessageResponse = {};

export const deleteDiscussionThreadMessage = (
  params: DeleteDiscussionThreadMessageParams
) => {
  return axiosLib.delete<DeleteDiscussionThreadMessageResponse>(
    `/discussion_messages/${params.id}`,
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};

export type UpdateContractParams = {
  path: {
    id: number;
  };
  params: {
    status:
      | 'accepted'
      | 'signed'
      | 'activated'
      | 'notice_of_termination_received'
      | 'terminated'
      | 'cancelled';
  };
};

export type UpdateContractResponse = {
  contract: {
    id: string;
    status: string;
    queued_at: Date;
    access_code: string;
  };
};

export const updateContract = (params: UpdateContractParams) => {
  return axiosLib.patch<UpdateContractResponse>(
    `/contracts/${params.path.id}/update_status`,
    {},
    {
      ...getColocAdminApiCallProperties(),
      params: { ...params.params },
    }
  );
};

export const getPropertyManagerList = () => {
  return axiosLib.get<{
    data: Array<{ id: number; first_name: string; last_name: string }>;
  }>(`/property_managers`, {
    ...getColocAdminApiCallProperties(),
  });
};

export const getNotificationList = () => {
  return axiosLib.get<{
    data: Array<NotificationType>;
  }>(`/notifications`, {
    ...getColocAdminApiCallProperties(),
  });
};

export const updateFcmToken = (params: { fcm_token: string }) => {
  return axiosLib.patch(`/notifications/update_fcm_token`, {}, {
      ...getColocAdminApiCallProperties(),
      params: { ...params },
    });
};

export const markAllNotificationsAsRead = () => {
  return axiosLib.patch(
    `/notifications/read_all`,
    {},
    {
      ...getColocAdminApiCallProperties(),
    }
  );
};
