import React, { useMemo, isValidElement, forwardRef } from 'react';
import { composeClassName } from '../../../../../utils/util_helpers';
import {
  afterTextInputStyle,
  getFormLabelStyle,
  inputParentContainer,
} from '../../../shared-styles';
import { TextInputProps } from './types';
import { TextInputHelpOrErrorMessage } from '../sub-components/text-input-help-or-error-message';
import { TextInputContentBeforeWrapper } from '../sub-components/text-input-content-before-wrapper';

// eslint-disable-next-line react/display-name
const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      showLabel = true,
      helpMessage,
      showHelpMessage = true,
      invalid = false,
      errorMessage,
      showErrorMessage = true,
      contentBefore,
      contentAfter,
      disabled = false,
      type = 'text',
      containerClassName,
      className,
      ...props
    },
    ref
  ) => {
    const labelStyle = useMemo(() => {
      return getFormLabelStyle({ disabled, showLabel });
    }, [disabled, showLabel]);

    return (
      <div
        className={composeClassName(
          inputParentContainer,
          containerClassName && containerClassName
        )}
      >
        <label htmlFor={label} className={labelStyle}>
          {label}
        </label>
        <div className="relative h-6 w-full">
          <TextInputContentBeforeWrapper disabled={disabled}>
            {contentBefore}
          </TextInputContentBeforeWrapper>
          <input
            ref={ref}
            id={label}
            className={composeClassName(
              'h-6 w-full rounded-sm border-[1px] border-solid border-E30 bg-E30 px-3 text-body text-text-primary outline-none placeholder:text-text-tertiary hover:bg-E50 focus:border-R300',
              invalid && 'border-C300 bg-E30',
              disabled && 'border-E50 bg-E50 text-E75',
              isValidElement(contentBefore) &&
                'pl-[calc(theme(padding.7)_+_theme(padding.2))]',
              isValidElement(contentAfter) &&
                'pr-[calc(theme(padding.7)_+_theme(padding.2))]',
              className && className
            )}
            type={type}
            disabled={disabled}
            {...props}
          />
          {contentAfter && (
            <div className={afterTextInputStyle}>{contentAfter}</div>
          )}
        </div>
        <TextInputHelpOrErrorMessage
          {...{
            invalid,
            showErrorMessage,
            showHelpMessage,
            helpMessage,
            errorMessage,
          }}
        />
      </div>
    );
  }
);

export { TextInput };
