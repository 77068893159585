import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PayoutSummaryCardProps } from './types';
import { Button } from '@/components/design-system/atoms/button';
import {
  PayoutCategory,
  PayoutProperty,
  PayoutTransaction,
} from '../../../../types';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { Amount } from '@/types/global';
import { theme } from '@/utils/util_theme';

const CardSummaryField: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <div className="flex flex-col">
      <span className="text-h5 font-medium text-text-primary">{title}</span>
      <span className="text-body text-text-secondary">{description}</span>
    </div>
  );
};

const CardSummary: React.FC<{
  property: PayoutProperty;
  amount: Amount;
  showRentDetails: () => void;
}> = ({ property, amount, showRentDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center gap-4">
      <CardSummaryField
        title={property.name.replace(/\./g, '')}
        description={`${property.landlord.first_name} ${property.landlord.last_name}`}
      />
      <div className="h-[54px] w-[1px] bg-E50" />
      <CardSummaryField
        title={amountToString(amount)}
        description={t(
          'js.features.admin.payouts.payout_summary_card.total_amount'
        )}
      />
      <div className="h-[54px] w-[1px] bg-E50" />
      <Button
        iconName="eye"
        variant="tertiary"
        size="medium"
        onClick={() => showRentDetails()}
      >
        {t('js.features.admin.payouts.payout_summary_card.view_more_button') ??
          ''}
      </Button>
    </div>
  );
};

const TransactionField: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <div className="flex w-[150px] flex-col">
      <span className="text-lead1 text-text-primary">{title}</span>
      <span className="text-body text-text-tertiary">{description}</span>
    </div>
  );
};

const SummarySection: React.FC<{
  title: string;
  transactions: Array<PayoutTransaction>;
  keepFeeAtSource?: boolean;
  category: PayoutCategory;
  totalFee?: Amount;
}> = ({ transactions, title, category, totalFee, keepFeeAtSource = false }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col gap-2">
      <span className="text-lead2 text-text-tertiary">{title}</span>
      <div className="flex w-full flex-col gap-3">
        {transactions.map((transaction) => {
          return (
            <div
              key={transaction.destination.id}
              className="flex items-center gap-3"
            >
              <TransactionField
                title={amountToString(transaction.amount)}
                description={transaction.description}
              />
              {transaction.destination.account_holder &&
                transaction.destination.bic && (
                  <>
                    <IconSaxIcon name="arrow-right" strokeWidth={1.3} />
                    <TransactionField
                      title={transaction.destination.account_holder}
                      description={transaction.destination.iban}
                    />
                  </>
                )}
            </div>
          );
        })}
      </div>
      {!keepFeeAtSource && category === 'management' && (
        <div className="flex items-center gap-2 text-footnote1 text-text-tertiary">
          <IconSaxIcon
            name="info-circle"
            strokeColor={theme?.colors.text.tertiary}
            size={20}
          />
          <span>
            {t(
              'js.features.admin.payouts.payout_summary_card.management_fee_info',
              { management_fee: amountToString(totalFee) }
            )}
          </span>
        </div>
      )}
    </div>
  );
};

const Spacer = () => {
  return <hr className="h-[1px] w-full bg-E50" />;
};

const PayoutSummaryCard: React.FC<PayoutSummaryCardProps> = ({
  payout,
  showRentDetails,
}) => {
  const { t } = useTranslation();
  const payoutsBySection: {
    landlord?: Array<PayoutTransaction>;
    management?: Array<PayoutTransaction>;
    refunds?: Array<PayoutTransaction>;
  } = useMemo(() => {
    const landlordTransactions: Array<PayoutTransaction> = [];
    const managementTransactions: Array<PayoutTransaction> = [];
    const refundsTransactions: Array<PayoutTransaction> = [];

    payout.transactions.forEach((transaction) => {
      if (transaction.payout_category === 'landlord') {
        landlordTransactions.push(transaction);
      } else if (transaction.payout_category === 'management') {
        managementTransactions.push(transaction);
      } else if (transaction.payout_category === 'refund') {
        refundsTransactions.push(transaction);
      }
    });

    return {
      landlord: landlordTransactions,
      management: managementTransactions,
      refunds: refundsTransactions,
    };
  }, [payout.transactions]);

  const managementFeesAmount = useMemo(() => {
    const centsAmount = payout.payout_rents.reduce((acc, rent) => {
      return acc + rent.management_fee.cents;
    }, 0);
    return { currency_iso: 'EUR', cents: centsAmount };
  }, [payout]);

  return (
    <div className="flex flex-col gap-3 rounded-lg border-[1px] border-solid border-E50 p-4">
      <CardSummary
        property={payout.property}
        amount={payout.amount}
        showRentDetails={() => showRentDetails()}
      />
      {payoutsBySection.landlord && payoutsBySection.landlord?.length > 0 && (
        <>
          <Spacer />
          <SummarySection
            category="landlord"
            title={t(
              'js.features.admin.payouts.payout_summary_card.sections.landlord'
            )}
            transactions={payoutsBySection.landlord}
          />
        </>
      )}
      {payoutsBySection.management && payoutsBySection.management?.length > 0 && (
        <>
          <Spacer />
          <SummarySection
            category="management"
            keepFeeAtSource={payout.keep_fee_at_source}
            title={t(
              'js.features.admin.payouts.payout_summary_card.sections.management'
            )}
            transactions={payoutsBySection.management}
            // @ts-ignore
            totalFee={managementFeesAmount}
          />
        </>
      )}
      {payoutsBySection.refunds && payoutsBySection.refunds?.length > 0 && (
        <>
          <Spacer />
          <SummarySection
            category="refund"
            title={t(
              'js.features.admin.payouts.payout_summary_card.sections.refunds'
            )}
            transactions={payoutsBySection.refunds}
          />
        </>
      )}
    </div>
  );
};

export { PayoutSummaryCard };
