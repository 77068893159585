import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { NavigationMenu } from '@/components/design-system/molecules/navigation-menu';
import { Divider } from '@/components/design-system/atoms/divider';
import { NotificationButton } from './NotificationButton';
import { CurrentUserInfo } from './CurrentUserInfo';

// eslint-disable-next-line no-shadow
export enum AdminRoutes {
  // Global
  People = 'People',
  Contracts = 'Contracts',
  Rents = 'Rents',
  Transactions = 'Transactions',
  Dashboard = 'Dashboard',
}

// Give me on object that has a string value for each key in AdminRoutes  (e.g. { People: 'People', Contracts: 'Contracts', ... })

const AdminSideNavigation: React.FC<{
  tabToSet: AdminRoutes;
}> = ({ tabToSet }) => {
  const [selectedTab, setSelectedTab] = useState<AdminRoutes>(tabToSet);
  const { t } = useTranslation();

  return (
    <NavigationMenu onChange={setSelectedTab} activeNavigationKey={selectedTab}>
      <NavigationMenu.NavigationMenuTabs>
        <NavigationMenu.NavigationMenuTab
          iconName="element-2"
          navigationKey={AdminRoutes.Dashboard}
          href="./dashboard"
        >
          {t(
            `js.features.admin.shared.navigation.${AdminRoutes.Dashboard.toLowerCase()}`
          )}
        </NavigationMenu.NavigationMenuTab>
        <NavigationMenu.NavigationMenuTab
          iconName="home"
          navigationKey={AdminRoutes.Contracts}
          href="./new-contracts"
        >
          {t(
            `js.features.admin.shared.navigation.${AdminRoutes.Contracts.toLowerCase()}`
          )}
        </NavigationMenu.NavigationMenuTab>
        <NavigationMenu.NavigationMenuTab
          iconName="arrow-3"
          navigationKey={AdminRoutes.Transactions}
          href="./transactions"
        >
          {t(
            `js.features.admin.shared.navigation.${AdminRoutes.Transactions.toLowerCase()}`
          )}
        </NavigationMenu.NavigationMenuTab>
        <NavigationMenu.NavigationMenuTab
          iconName="receipt-1"
          navigationKey={AdminRoutes.Rents}
          href="./new-rents"
        >
          {t(
            `js.features.admin.shared.navigation.${AdminRoutes.Rents.toLowerCase()}`
          )}
        </NavigationMenu.NavigationMenuTab>
        <NavigationMenu.NavigationMenuTab
          iconName="people"
          navigationKey={AdminRoutes.People}
          href="./colocs"
        >
          {t(
            `js.features.admin.shared.navigation.${AdminRoutes.People.toLowerCase()}`
          )}
        </NavigationMenu.NavigationMenuTab>
      </NavigationMenu.NavigationMenuTabs>
      <div className="px-7">
        <Divider />
      </div>
      <div className="flex items-center justify-between px-7 py-3">
        <CurrentUserInfo />
        <NotificationButton />
      </div>

    </NavigationMenu>
  );
};

export { AdminSideNavigation };
