import { getI18n } from 'react-i18next';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { RoomAssignmentListSortType } from '@/lib/coloc-api/admin';
import { useColocApi } from '@/hooks/use-coloc-api';
import { RoomAssignmentStatus } from '@/features/admin/contracts/types';
import { PropertyManager } from '@/features/admin/contracts/types';


const i18n = getI18n();

type RoomAssignmentStatusOption = {
  status: RoomAssignmentStatus;
  displayValue: string;
};

const assignmentStatusList: Array<RoomAssignmentStatusOption> = [
  {
    status: 'not_available',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.not_available'
    ),
  },
  {
    status: 'searching_applicants',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.searching_applicants'
    ),
  },
  {
    status: 'awaiting_signature',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.awaiting_signature'
    ),
  },
  {
    status: 'awaiting_deposit',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.awaiting_deposit'
    ),
  },
  {
    status: 'deposit_paid',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.deposit_paid'
    ),
  },
  {
    status: 'occupied',
    displayValue: i18n.t(
      'js.features.admin.contracts.room_assignment.status_options.occupied'
    ),
  },
];

const useRoomAssignmentListFilters = () => {
  const [selectedAssignmentStatus, setSelectedAssignmentStatus] = useState<
    RoomAssignmentStatusOption | undefined
  >(undefined);
  const [assignmentStatusQuery, setAssignmentStatusQuery] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const {
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    setSortDirection,
  } = useColocApi<RoomAssignmentListSortType>()!;
  const [selectedHouse, setSelectedHouse] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [housesQuery, setHousesQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState<
    { id: string; name: string } | undefined
  >(undefined);
  const [citiesQuery, setCitiesQuery] = useState('');

  const [selectedPropertyManager, setSelectedPropertyManager] =
    useState<PropertyManager | null>(null);

  const [rentalType, setRentalType] = useState<
    { slug: string; displayValue: string } | undefined
  >(undefined);

  // Set default sorting to urgency
  useEffect(() => {
    setColumnToSort('urgency');
    setSortDirection('descending');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateRange = useCallback(
    (new_date_range: [Date | null, Date | null]) => {
      setStartDate(new_date_range[0] ?? undefined);
      setEndDate(new_date_range[1] ?? undefined);
    },
    []
  );

  const filteredAssignmentStatusArray = useMemo(() => {
    return assignmentStatusList.filter((statusObject) =>
      statusObject.displayValue.includes(assignmentStatusQuery)
    );
  }, [assignmentStatusQuery]);

  return {
    selectedAssignmentStatus,
    setSelectedAssignmentStatus,
    setAssignmentStatusQuery,
    assignmentStatusQuery,
    filteredAssignmentStatusArray,
    startDate,
    endDate,
    setDateRange,
    searchQuery,
    setSearchQuery,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
    setSelectedHouse,
    housesQuery,
    setHousesQuery,
    setSortDirection,
    selectedCity,
    setSelectedCity,
    citiesQuery,
    setCitiesQuery,
    selectedPropertyManager,
    setSelectedPropertyManager,
    rentalType,
    setRentalType,
  };
};

const GetRoomAssignmentListFiltersContext = createContext<
  ReturnType<typeof useRoomAssignmentListFilters> | undefined
>(undefined);

const GetRoomAssignmentListFiltersContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useRoomAssignmentListFilters();

  return (
    <GetRoomAssignmentListFiltersContext.Provider value={hook}>
      {children}
    </GetRoomAssignmentListFiltersContext.Provider>
  );
};

const useRoomAssignmentListFiltersContext = () => {
  const context = useContext(GetRoomAssignmentListFiltersContext);

  if (context === null) {
    throw new Error(
      'useRoomAssignmentListFiltersContext must be used within a GetRoomAssignmentListFiltersContextProvider'
    );
  }

  return context;
};

export {
  GetRoomAssignmentListFiltersContextProvider,
  useRoomAssignmentListFiltersContext,
};
