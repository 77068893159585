import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DiscussionThreadMessageProps } from './types';
import { composeClassName } from '@/utils/util_helpers';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { theme } from '@/utils/util_theme';
import { DropdownMenu } from '@/components/design-system/molecules/dropdown-menu';

const DiscussionThreadMessageCard: React.FC<DiscussionThreadMessageProps> = ({
  message,
  messageByCurrentUser,
  className,
  onDelete,
}) => {
  const { t } = useTranslation();

  const dateText = useMemo(() => {
    const messageTime = new Date(message.created_at).getTime();
    const currentTime = Date.now();
    const diff = currentTime - messageTime;

    if (diff < 60 * 1000) {
      // Less than a minute
      return t('js.features.shared.discussion_thread.message.now_text');
    }

    if (diff < 60 * 60 * 1000) {
      // Less than an hour
      return `${Math.floor(diff / (60 * 1000))}m`;
    }

    if (diff < 24 * 60 * 60 * 1000) {
      // Less than a day
      return `${Math.floor(diff / (60 * 60 * 1000))}h`;
    }

    return format(new Date(message.created_at), 'yyyy-MM-dd HH:mm:ss');
  }, [message.created_at, t]);

  const htmlContent = useMemo(() => {
    return marked.parse(message.content);
  }, [message.content]);

  return (
    <div
      className={composeClassName(
        'flex flex-col gap-3 rounded-lg border-[1px] border-solid border-border-primaryLight p-4',
        className && className
      )}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          <Avatar
            size="s"
            firstName={message.user.first_name}
            lastName={message.user.last_name}
            {...(message.user.avatar_url && { src: message.user.avatar_url })}
          />
          <span className="text-body font-medium text-text-primaryHeavy">
            {messageByCurrentUser
              ? t('js.features.shared.discussion_thread.message.current_user')
              : `${message.user.first_name} ${message.user.last_name}`}
          </span>
        </div>
        {messageByCurrentUser && (
          <DropdownMenu>
            <DropdownMenu.DropdownMenuButton>
              <IconSaxIcon name="more" className="rotate-90" />
            </DropdownMenu.DropdownMenuButton>
            <DropdownMenu.DropdownMenuOptions className="right-[0px]">
              <DropdownMenu.DropdownMenuOption
                iconName="trash"
                destructive
                as="button"
                onClick={() => onDelete()}
              >
                {t(
                  'js.features.shared.discussion_thread.message.delete_message'
                ) ?? ''}
              </DropdownMenu.DropdownMenuOption>
            </DropdownMenu.DropdownMenuOptions>
          </DropdownMenu>
        )}
      </div>
      <div className="h-[1px] w-full bg-border-primaryLight" />

      <span
        className="markdown-content text-lead2 font-medium text-text-primaryHeavy"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />

      <div className="flex flex-row items-center gap-1">
        <IconSaxIcon
          name="clock-1"
          size={18}
          strokeWidth={1.5}
          strokeColor={theme?.colors.fill.icon.primaryMedium}
        />
        <span className="text-footnote1 text-text-primaryMedium">
          {dateText}
        </span>
      </div>
    </div>
  );
};

export { DiscussionThreadMessageCard };
