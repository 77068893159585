import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';

import { PopUp } from '@/components/design-system/molecules/pop-up';
import { useDeleteRentTransaction } from '../../hooks/rent-transactions/use-delete-rent-transaction';
import { useGetRentsListContext } from '../../hooks/use-get-rents-list';

const DeleteRentTransactionModal = () => {
  const { t } = useTranslation();
  const {
    showDeleteRentTransactionModal,
    toggleShowDeleteRentTransactionModal,
  } = useGetRentsListContext()!;
  const { mutate } = useDeleteRentTransaction()!;

  const { show, transaction } = showDeleteRentTransactionModal;

  return (
    <>
      {show && transaction && (
        <Modal
          open={show}
          onClose={() =>
            toggleShowDeleteRentTransactionModal({
              show: false,
              transaction: undefined,
            })
          }
        >
          <PopUp
            type="default"
            buttonOrder="secondary-first"
            title={t(
              'js.features.admin.rents.delete_rent_transaction_modal.title'
            )}
            description={
              t('js.features.admin.rents.delete_rent_transaction_modal.text', {
                amount: amountToString(transaction.amount),
              }) ?? ''
            }
            primaryButtonProps={{
              onClick: () =>
                toggleShowDeleteRentTransactionModal({
                  show: false,
                  transaction: undefined,
                }),
              title:
                t(
                  'js.features.admin.rents.delete_rent_transaction_modal.cancel_button_label'
                ) ?? '',
            }}
            secondaryButtonProps={{
              disabled: mutate.isLoading,
              onClick: () => mutate.mutate({ id: transaction.id }),
              title:
                t(
                  'js.features.admin.rents.delete_rent_transaction_modal.delete_button_label'
                ) ?? '',
            }}
          />
        </Modal>
      )}
    </>
  );
};

export { DeleteRentTransactionModal };
