import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/design-system/atoms/button';
import { AddRentTransactionRowProps } from './types';

const AddRentTransactionRow: React.FC<AddRentTransactionRowProps> = ({
  onClickAdd,
  onClickClose,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center bg-R300">
      <div className="flex items-center gap-4 py-2 px-7">
        <span className="text-body text-text-primary">
          {t(
            'js.features.admin.rents.rent_transaction_row.add_transaction_row.title'
          )}
        </span>
        <div className="flex items-center gap-[20px]">
          <Button
            iconName="add"
            variant="secondary"
            disabled={disabled}
            {...(onClickAdd && { onClick: onClickAdd })}
          >
            {t(
              'js.features.admin.rents.rent_transaction_row.add_transaction_row.add_button'
            ) ?? ''}
          </Button>
          <Button
            variant="tertiary"
            {...(onClickClose && { onClick: onClickClose })}
          >
            {t(
              'js.features.admin.rents.rent_transaction_row.add_transaction_row.close_button'
            ) ?? ''}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddRentTransactionRow };
