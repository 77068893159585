import React from 'react';

import { composeClassName } from '@/utils/util_helpers';

import { GetOutgoingTransactionsListContextProvider } from './hooks/use-get-outgoing-transactions';
import { OutgoingTransactionsListTable } from './components/outgoing-transactions-list-table';
import { GetOutgoingTransactionsListFilterContextProvider } from './hooks/use-get-outgoing-transactions-list-filter';
import { OutgoingTransactionsListFilterContainer } from './components/outgoing-transactions-list-filter-container';
import { GetOutgoingTransactionPaymentContextProvider } from './hooks/use-get-outgoing-transaction-payment';

const OutgoingTransactionsListContent = () => {
  return (
    <div className={composeClassName('flex h-full w-full flex-1 flex-col')}>
      <OutgoingTransactionsListFilterContainer />
      <OutgoingTransactionsListTable />
    </div>
  );
};

const OutgoingTransactionsList = () => {
  return (
    <GetOutgoingTransactionsListFilterContextProvider>
      <GetOutgoingTransactionsListContextProvider>
        <GetOutgoingTransactionPaymentContextProvider>
          <OutgoingTransactionsListContent />
        </GetOutgoingTransactionPaymentContextProvider>
      </GetOutgoingTransactionsListContextProvider>
    </GetOutgoingTransactionsListFilterContextProvider>
  );
};

export { OutgoingTransactionsList };
