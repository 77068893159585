import { Amount, CurrencyIso } from '@/features/admin/rents/types';

type CurrencyMap = {
  [key in CurrencyIso]: string;
};

const currencyMap: CurrencyMap = {
  EUR: '€',
  USD: '$',
};

const currencyIsoToString = (currencyIso: CurrencyIso): string => {
  return currencyMap[currencyIso];
};

const amountToString = (amount: Amount) => {
  let centsToFull = String((amount.cents / 100).toFixed(2));

  if (centsToFull.endsWith('.00')) {
    centsToFull = centsToFull.slice(0, centsToFull.length - 3);
  }
  return `${currencyIsoToString(amount.currency_iso)}${centsToFull}`;
};

const numberToCurrency = (amount: Number, currency_iso = 'EUR') => {
  return `${currencyIsoToString(currency_iso)}${amount}`;
};

export { amountToString, currencyIsoToString, numberToCurrency };
