import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';

import { RowSelectionState } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';

import {
  GetRentsListResponse,
  rentListSortableIds,
  RentsListSortType,
} from '@/lib/coloc-api/admin';
import { getRentsList } from '@/services/coloc-api/admin';
import { useDebounce } from '@/hooks/use-debounce';
import { useColocApi } from '@/hooks/use-coloc-api';
import { Rent, RentTransaction } from '../../../../types';
import { useRentsListFiltersContext } from '../use-rents-list-filter';

const useGetRentsList = () => {
  const {
    searchQuery,
    endDate,
    startDate,
    rentStatusQuery,
    selectedListRentStatus,
    selectedListRentReminder,
    setColumnToSort,
    sortDirection,
    selectedColumnToSort,
    selectedHouse,
  } = useRentsListFiltersContext()!;
  const {
    setPage,
    setPageLimit,
    page,
    pageLimit,
    resultSummary,
    setPaginationResponse,
  } = useColocApi<RentsListSortType>()!;
  const debouncedSearchQuery: string = useDebounce(searchQuery ?? '', 500);
  const sortableColumns = rentListSortableIds;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [showAddRentTransactionModal, toggleShowAddRentTransactionModal] =
    useState<{ show: boolean; rent: Rent | undefined }>({
      show: false,
      rent: undefined,
    });
  const [showDeleteRentTransactionModal, toggleShowDeleteRentTransactionModal] =
    useState<{ show: boolean; transaction: RentTransaction | undefined }>({
      show: false,
      transaction: undefined,
    });
  const [
    showAddedRentTransactionConfirmationModal,
    toggleShowAddedRentTransactionConfirmationModal,
  ] = useState(false);
  const [
    showMarkRentsAsPaidConfirmationModal,
    toggleShowMarkRentsAsPaidConfirmationModal,
  ] = useState(false);
  const queryClient = useQueryClient();
  const [
    resetConnectedTableExpandedRowSelection,
    setResetTableExpandedRowsSelectionCallBack,
  ] = useState<Function | undefined>(undefined);
  const [resetConnectedTableRowSelection, setResetTableRowSelectionCallBack] =
    useState<Function | undefined>(undefined);

  const queryKey: QueryKey = useMemo(
    () => [
      'getRentsList',
      selectedListRentStatus,
      selectedListRentReminder,
      startDate,
      endDate,
      debouncedSearchQuery,
      searchQuery,
      pageLimit,
      page,
      selectedColumnToSort,
      sortDirection,
      selectedHouse,
    ],
    [
      debouncedSearchQuery,
      endDate,
      page,
      pageLimit,
      searchQuery,
      selectedColumnToSort,
      selectedListRentStatus,
      selectedListRentReminder,
      sortDirection,
      startDate,
      selectedHouse,
    ]
  );

  const fetch = useQuery({
    queryKey,
    queryFn: () =>
      getRentsList({
        ...(startDate && { start_date: startDate }),
        ...(endDate && { end_date: endDate }),
        ...(pageLimit && { page_limit: pageLimit }),
        ...(page && { page }),
        ...(sortDirection && { sort_direction: sortDirection }),
        ...(selectedColumnToSort && { sort: selectedColumnToSort }),
        ...(selectedHouse && { house_id: selectedHouse.id }),
        ...(selectedListRentStatus && {
          status: selectedListRentStatus.status,
        }),
        ...(selectedListRentReminder && {
          reminders_sent: selectedListRentReminder.reminders_sent,
        }),
        ...(debouncedSearchQuery &&
          debouncedSearchQuery !== '' && { query: debouncedSearchQuery }),
      }),
    onSettled: () => {
      resetConnectedTableExpandedRowSelection &&
        resetConnectedTableExpandedRowSelection();
      resetConnectedTableRowSelection && resetConnectedTableRowSelection();
    },
    keepPreviousData: true,
  });

  const rowSelectionFullData = useMemo(() => {
    if (fetch?.data?.data && fetch?.data?.data.data?.length >= 1) {
      const dataCopy = [...fetch.data.data.data];
      const selectedData: Array<Rent> = [];
      // @ts-ignore
      Object.keys(rowSelection).forEach((value: number) => {
        selectedData.push(dataCopy[value]);
      });
      return selectedData;
    }
    return [];
  }, [fetch.data?.data, rowSelection]);

  const updateRentInCache = useCallback(
    (rent: Rent) => {
      queryClient.setQueryData(
        queryKey,
        (oldData: AxiosResponse<GetRentsListResponse, any> | undefined) => {
          if (oldData?.data) {
            const oldDataCopy = { ...oldData };
            const newData = oldDataCopy.data.data.map((mappedRent) => {
              if (mappedRent.id === rent.id) {
                return rent;
              }

              return mappedRent;
            });
            oldDataCopy.data.data = newData;

            return oldDataCopy;
          }
          return oldData;
        }
      );
    },
    [queryClient, queryKey]
  );

  const navigateToPayoutsScreen = () => {
    const selectedRentIdsAsString = rowSelectionFullData
      .map((rent) => rent.id)
      .join(',');

    window.location.replace(
      `/admin/payout-summary?rent_ids=${selectedRentIdsAsString}`
    );
  };

  useEffect(() => {
    setPaginationResponse(
      fetch.data?.data.pagination
        ? { pagination: fetch.data?.data.pagination }
        : undefined
    );
  }, [fetch.data?.data.pagination, setPaginationResponse]);

  // Reset rowSelection when changing page.
  useEffect(() => {
    setRowSelection({});
  }, [page, sortDirection, selectedColumnToSort]);

  return {
    pageLimit,
    setPageLimit,
    fetch,
    rentStatusQuery,
    startDate,
    endDate,
    searchQuery,
    setPage,
    sortableColumns,
    selectedColumnToSort,
    setColumnToSort,
    sortDirection,
    resultSummary,
    rowSelection,
    rowSelectionFullData,
    setRowSelection,
    showAddRentTransactionModal,
    toggleShowAddRentTransactionModal,
    showAddedRentTransactionConfirmationModal,
    toggleShowAddedRentTransactionConfirmationModal,
    showDeleteRentTransactionModal,
    toggleShowDeleteRentTransactionModal,
    updateRentInCache,
    setResetTableExpandedRowsSelectionCallBack,
    setResetTableRowSelectionCallBack,
    showMarkRentsAsPaidConfirmationModal,
    toggleShowMarkRentsAsPaidConfirmationModal,
    navigateToPayoutsScreen,
  };
};

const GetRentsSummaryHousesContext = createContext<
  ReturnType<typeof useGetRentsList> | undefined
>(undefined);

const GetRentsListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const hook = useGetRentsList();

  return (
    <GetRentsSummaryHousesContext.Provider value={hook}>
      {children}
    </GetRentsSummaryHousesContext.Provider>
  );
};

const useGetRentsListContext = () => {
  const context = useContext(GetRentsSummaryHousesContext)!;

  if (context === null) {
    throw new Error(
      'useGetRentsSummaryHousesContext must be used within a GetRentsSummaryHousesContextProvider'
    );
  }

  return context;
};

export { GetRentsListContextProvider, useGetRentsListContext };
