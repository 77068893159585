import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { composeClassName } from '@/utils/util_helpers';
import { usePresentationContext } from '../../hooks/use-presentation-context';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const Presentation = () => {
  const { setNumPages, setPDFLoadingStatus, pageNumber, numPages } =
    usePresentationContext()!;
  const { screenIsSmallerThan } = useWindowDimensions();

  return (
    <div
      className={composeClassName(
        'relative mt-[4.8rem] mb-4 aspect-video w-full overflow-hidden rounded-lg border-[3px] border-solid border-E50 ',
        'md:mt-[5.6rem] md:mb-10',
        'lg:mb-[7.7rem]'
      )}
    >
      <Document
        file={require('@assets/coloc-teaser.pdf')}
        className="w-full bg-white"
        onLoadError={(_) => setPDFLoadingStatus('failed')}
        onLoadSuccess={(pdf) => {
          setPDFLoadingStatus('loaded');
          setNumPages(pdf.numPages);
          pdf.saveDocument();
        }}
      >
        <Page
          pageNumber={pageNumber}
          {...(screenIsSmallerThan('sm') && { width: 640, height: 360 })}
          className="[&>canvas]:!h-full [&>canvas]:!w-full"
        />
      </Document>
      <span className="absolute bottom-5 right-5 z-10 hidden text-lead2 text-text-secondary md:block">{`Slide ${pageNumber} of ${numPages}`}</span>
    </div>
  );
};

export { Presentation };
