import { useTranslation } from 'react-i18next';
import React from 'react';
import { composeClassName } from '@/utils/util_helpers';
import { RentTransactionsTableProps } from './types';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';
import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { Button } from '@/components/design-system/atoms/button';

const RentTransactionTableHeader: React.FC<{ children: string }> = ({
  children,
}) => {
  return (
    <th className="text-start text-footnote1 text-text-tertiary">{children}</th>
  );
};

const RentTransactionTableCell: React.FC<{ children: string }> = ({
  children,
}) => {
  return (
    <td className="py-[12px] pr-[200px] text-body text-text-primary">
      {children}
    </td>
  );
};

const RentTransactionsTable: React.FC<RentTransactionsTableProps> = ({
  data,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <table>
      <thead className="pb-3">
        <tr>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.transaction_type'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.registered'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.date'
            )}
          </RentTransactionTableHeader>
          <RentTransactionTableHeader>
            {t(
              'js.features.admin.rents.rent_transaction_row.table_columns.amount'
            )}
          </RentTransactionTableHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((transaction, transaction_index) => {
          return (
            <tr
              key={transaction.id}
              className={composeClassName(
                'w-full',
                // eslint-disable-next-line no-unsafe-optional-chaining
                transaction_index < data.length - 1 &&
                  'border-opacity-10" border-b-[1px] border-solid border-E50'
              )}
            >
              <RentTransactionTableCell>
                {transaction.amount.cents >= 0
                  ? t(
                      `js.features.admin.rents.rent_transaction_row.status_transaction_type.payment`
                    ) ?? ''
                  : t(
                      `js.features.admin.rents.rent_transaction_row.status_transaction_type.refund`
                    ) ?? ''}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {transaction.manual
                  ? t(
                      `js.features.admin.rents.rent_transaction_row.status_registered.manual`
                    ) ?? ''
                  : t(
                      `js.features.admin.rents.rent_transaction_row.status_registered.automatically`
                    ) ?? ''}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {getFullDate(new Date(transaction.date))}
              </RentTransactionTableCell>
              <RentTransactionTableCell>
                {amountToString(transaction.amount)}
              </RentTransactionTableCell>
              <td className="items-center text-center align-middle">
                <Button
                  variant="icon"
                  iconName="trash"
                  className={`h-full w-full items-center justify-center ${
                    transaction.can_delete ? '' : 'hidden'
                  }`}
                  onClick={() => onDelete(transaction)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { RentTransactionsTable };
