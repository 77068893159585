import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OutgoingTransactionSyncButtonProps } from './types';
import { Button } from '@/components/design-system/atoms/button';
import { useGetOutgoingTransactionPaymentContext } from '@/features/admin/transactions/components/outgoing-transactions-list/hooks/use-get-outgoing-transaction-payment';

const OutgoingTransactionSyncButton: React.FC<OutgoingTransactionSyncButtonProps> =
  ({ outgoingTransaction }) => {
    const { t } = useTranslation();
    const { setPaymentId, fetchingPayment, paymentId } =
      useGetOutgoingTransactionPaymentContext()!;

    const isFetchingPayment = useMemo(() => {
      return fetchingPayment && paymentId === outgoingTransaction.payment_id;
    }, [fetchingPayment, outgoingTransaction.payment_id, paymentId]);

    return (
      <Button
        onClick={() => setPaymentId(outgoingTransaction.payment_id)}
        loading={isFetchingPayment}
        disabled={!isFetchingPayment && fetchingPayment}
      >
        {t(
          'js.features.admin.transactions.outgoing_transactions_list.table_cells.sync_payment_button'
        ) ?? ''}
      </Button>
    );
  };

export { OutgoingTransactionSyncButton };
