import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/design-system/atoms/modal';
import { AddRentTransactionModalProps } from './types';
import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { TextInput } from '@/components/design-system/atoms/text/text-input';
import { DatePicker } from '@/components/design-system/molecules/datepicker';
import { Button } from '@/components/design-system/atoms/button';
import { useAddRentTransaction } from '../../hooks/rent-transactions/use-add-rent-transaction';
import { getFullDate } from '@/utils/util_helpers/formatting/dates';

const AddRentTransactionModal: React.FC<AddRentTransactionModalProps> = ({
  open,
  onClose,
  rent,
}) => {
  const { t } = useTranslation();
  const [selectedTransactionAmount, setRentTransactionAmount] = useState<
    number | undefined
  >();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { mutate } = useAddRentTransaction()!;

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-[650px] overflow-hidden rounded-lg bg-white shadow-medium">
        <div className="flex h-[102px] w-full items-center justify-between bg-R300 px-7 py-3">
          <div className="flex flex-col">
            <span className="text-lead2 font-medium text-text-primary opacity-60">
              {t(
                'js.features.admin.rents.add_rent_transaction_modal.title_first_part'
              )}
            </span>
            <span className="text-h4 font-bold text-text-primary">
              {t(
                'js.features.admin.rents.add_rent_transaction_modal.title_second_part'
              )}
            </span>
          </div>
          <IconSaxIcon name="wallet-add" size={70} className="opacity-20" />
        </div>
        <div className="flex w-full overflow-hidden">
          <div className="flex flex-col gap-4 bg-E30 px-4 pt-4">
            <div>
              <span className="text-body font-medium italic text-text-primary opacity-60">
                {t(
                  'js.features.admin.rents.add_rent_transaction_modal.how_to_section.title'
                )}
              </span>
              <div className="flex flex-col gap-3">
                <div className="flex gap-1 pt-[6px] text-body text-text-primary">
                  <span>1.</span>
                  <span>
                    {t(
                      'js.features.admin.rents.add_rent_transaction_modal.how_to_section.step_one'
                    )}
                  </span>
                </div>
                <div className="flex gap-1 text-body text-text-primary">
                  <span>2.</span>
                  <span>
                    {t(
                      'js.features.admin.rents.add_rent_transaction_modal.how_to_section.step_two'
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="h-[1px] w-full bg-E50" />
            <div className="flex flex-col gap-1 text-body text-text-primary">
              <span className="font-medium italic opacity-60">
                {t(
                  'js.features.admin.rents.add_rent_transaction_modal.rent_info.room',
                  { room_number: rent.room.room_number }
                )}
              </span>
              <span className="font-bold">{rent.tenant.name}</span>
              <span className="uppercase opacity-60">
                {rent.room.house.name}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[105px] bg-white p-7">
            <div className="flex w-[320px] flex-col gap-4">
              <TextInput
                label={t(
                  'js.features.admin.rents.add_rent_transaction_modal.form.amount_input_label'
                )}
                type="number"
                value={selectedTransactionAmount}
                onChange={(e) => {
                  setRentTransactionAmount(Number(e.target.value));
                }}
              />
              <DatePicker
                label={t(
                  'js.features.admin.rents.add_rent_transaction_modal.form.date_input_label'
                )}
                type="date"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </div>
            <div className="flex items-center gap-4">
              <Button
                variant="primary"
                disabled={!selectedDate || !selectedTransactionAmount}
                onClick={() => {
                  selectedTransactionAmount &&
                    selectedDate &&
                    mutate.mutate({
                      rent_id: rent.id,
                      transaction: {
                        amount: selectedTransactionAmount,
                        date: getFullDate(selectedDate),
                      },
                    });
                }}
              >
                {t(
                  'js.features.admin.rents.add_rent_transaction_modal.form.add_button'
                ) ?? ''}
              </Button>
              <Button
                variant="ghost"
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {t(
                  'js.features.admin.rents.add_rent_transaction_modal.form.cancel_button'
                ) ?? ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { AddRentTransactionModal };
