import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '@/features/marketplace/components/section-title';
import { DEFAULT_CONTENT_WIDTH } from '@/features/marketplace/constants';
import { composeClassName } from '@/utils/util_helpers';
import { Button } from '@/components/design-system/atoms/button';
import { useWindowDimensions } from '@/hooks/use-window-dimensions';

const backgroundImage = require(`@assets/images/marketplace/about-us/section-3-bg.svg`);

type PreviewType = 'logo' | 'photo';

type PreviewAsset = {
  link: string;
  imgSrc: string;
  fileName: string;
};

const logos: Array<PreviewAsset> = [
  {
    link: 'https://www.dropbox.com/sh/zl3xrka9gwqu6c2/AAC5uy0TrKqA5ah2PsrG_9-ua?dl=0',
    imgSrc: 'color-coloc',
    fileName: 'color_logo_pack',
  },
  {
    link: 'https://www.dropbox.com/sh/bsncnnprni5oplr/AAD1mvOe_iYwgN9AoBpLrB7Ja?dl=0',
    imgSrc: 'bw-coloc',
    fileName: 'bw_logo_pack',
  },
];

const pictures: Array<PreviewAsset> = [
  {
    link: 'https://www.dropbox.com/s/hngtyw132a5lzk1/eetkamer%289%29.jpg?dl=0',
    imgSrc: 'eetkamer',
    fileName: 'zandpoort_kitchen.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/y6888hu66215g3b/Grand-Hospice-24-Living-Room.jpg?dl=0',
    imgSrc: 'grand-hospice',
    fileName: 'grand_hospice_living_room.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/4yk6m4d13rwumt7/kamer-6%282%29.jpg?dl=0',
    imgSrc: 'kamer-6',
    fileName: 'zandpoort_bedroom.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/lmxnww6cr955z0p/kamer-9%285%29.jpg?dl=0',
    imgSrc: 'kamer-9',
    fileName: 'zandpoort_bedroom.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/r7dkotlv97iiwms/living-room%282%29.jpg?dl=0',
    imgSrc: 'living-room',
    fileName: 'tiensevest_living_room.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/qo15nrcz0ya9imt/Zandpoort-02a.jpg?dl=0',
    imgSrc: 'zandpoort',
    fileName: 'zandpoort_flat.jpg',
  },
  {
    link: 'https://www.dropbox.com/s/5ld4d0e9ro5zem4/badkamer-2%284%29.jpg?dl=0',
    imgSrc: 'badkamer',
    fileName: 'zandpoort_bathroom.jpg',
  },
];

const allFiles =
  'https://www.dropbox.com/sh/qokhl8u982dzokw/AAAbNgT1BXlMr3Uhjltypet6a?dl=0';

const PreviewItem: React.FC<{ asset: PreviewAsset }> = ({ asset }) => {
  return (
    <div
      className={composeClassName(
        'flex w-full flex-col gap-3 rounded-lg border-[1px] border-solid border-E50 p-4',
        'sm:w-[45%]',
        'lg:w-[30%]'
      )}
    >
      <picture>
        <source
          srcSet={`${require(`@assets/images/marketplace/press/asset_previews/${asset.imgSrc}.webp`)} 1x, ${require(`@assets/images/marketplace/press/asset_previews/${asset.imgSrc}@2x.webp`)} 2x`}
          type="image/webp"
        />
        <img
          src={require(`@assets/images/marketplace/press/asset_previews/${asset.imgSrc}`)}
          alt={asset.fileName}
          className="aspect-video h-auto w-full rounded object-cover"
        />
      </picture>
      <div className="flex items-center justify-between py-3 px-4">
        <span className="truncate pr-3 text-lead2 text-text-primary">
          {asset.fileName}
        </span>
        <Button
          variant="ghost"
          iconName="document-download"
          size="large"
          type="button"
          onClick={() => window.open(asset.link, '_blank')}
        />
      </div>
    </div>
  );
};

const PreviewSection: React.FC<{ type: PreviewType }> = ({ type }) => {
  const { t } = useTranslation();
  const sourceToMap = type === 'logo' ? logos : pictures;

  return (
    <div className="w-full">
      <h3 className="text-h4 font-medium text-text-primary">
        {t(`js.features.marketplace.press.section3.${type}_title`)}
      </h3>
      <div className="mt-7 flex w-full flex-wrap gap-4">
        {sourceToMap.map((asset) => {
          return <PreviewItem key={asset.fileName} asset={asset} />;
        })}
      </div>
    </div>
  );
};

const ThirdSection = () => {
  const { screenIsSmallerThan } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <section className="bg-[#FBFBFB]">
      <div
        className={composeClassName('h-10 w-full', 'sm:h-[12rem]')}
        style={{
          backgroundSize: '100% auto',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div
        className={composeClassName(
          DEFAULT_CONTENT_WIDTH,
          'm-auto pb-8',
          'md:pb-[10.5rem]',
          'lg:pb-[14rem]'
        )}
      >
        <SectionTitle>{'Press and \n media kit'}</SectionTitle>
        <div
          className={composeClassName(
            'mt-8 flex flex-wrap justify-between gap-x-4 gap-y-7'
          )}
        >
          <PreviewSection type="logo" />
          <PreviewSection type="photo" />
        </div>
        <Button
          onClick={() => window.open(allFiles, '_blank')}
          type="button"
          size="large"
          variant="secondary"
          iconName="document-download"
          className={composeClassName(
            'mt-4',
            'sm:mt-7',
            screenIsSmallerThan('sm') && 'w-full'
          )}
        >
          {t('js.features.marketplace.press.section3.download_all_button') ??
            ''}
        </Button>
      </div>
    </section>
  );
};

export { ThirdSection };
