import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RentTotalAmountCellProps } from './types';

import { amountToString } from '@/utils/util_helpers/formatting/amount_to_string';
import { Amount } from '@/types/global';
import { IconSaxIcon } from '@/components/design-system/atoms/icon/iconsax-icon';
import { theme } from '@/utils/util_theme';
import { PopOver } from '@/components/design-system/molecules/pop-over';

const RentTotalAmountCellContent: React.FC<RentTotalAmountCellProps> = ({
  chargesAmount,
  netAmount,
  discountAmount,
  paidAmount,
  unpaidAmount,
}) => {
  const { t } = useTranslation();

  const totalAmount: Amount = useMemo(() => {
    const totalCents =
      chargesAmount.cents + netAmount.cents - discountAmount.cents;

    return { cents: totalCents, currency_iso: chargesAmount.currency_iso };
  }, [chargesAmount, discountAmount, netAmount]);

  return (
    <PopOver placement="bottom-start" controlMethod="click">
      <PopOver.PopOverTrigger>
        <div className="flex items-center rounded-sm hover:bg-E50">
          <IconSaxIcon
            name="eye"
            strokeWidth={1.5}
            size={18}
            strokeColor={theme?.colors.text.secondary}
          />
          <span
            className="line-clamp-1 overflow-hidden break-all pl-1 text-body font-medium text-text-secondary underline"
            style={{ whiteSpace: 'initial' }}
          >
            {amountToString(totalAmount)}
          </span>
        </div>
      </PopOver.PopOverTrigger>
      <PopOver.PopOverContent
        className="min-w-[250px] !max-w-[400px]"
        title={
          t(
            'js.features.admin.rents.rent_total_amount_cell_content.total_title',
            { amount: amountToString(totalAmount) }
          ) ?? ''
        }
      >
        <div className="flex w-full flex-col text-body text-text-secondary">
          <ul className="list-disc pl-3 text-left">
            <li>
              <span>
                {t(
                  'js.features.admin.rents.rent_total_amount_cell_content.net_rent'
                )}
              </span>
              <span className="font-medium">{` ${amountToString(
                netAmount
              )}`}</span>
            </li>
            <li>
              <span>
                {t(
                  'js.features.admin.rents.rent_total_amount_cell_content.charges'
                )}
              </span>
              <span className="font-medium">{` ${amountToString(
                chargesAmount
              )}`}</span>
            </li>
            <li>
              <span>
                {t(
                  'js.features.admin.rents.rent_total_amount_cell_content.discount'
                )}
              </span>
              <span className="font-medium">{` ${amountToString(
                discountAmount
              )}`}</span>
            </li>
          </ul>
          <hr className="h-[1px] w-full border-t-[1px] border-dashed border-E75" />
          <div>
            <ul className="list-disc pl-3 text-left">
              <li>
                <span>
                  {t(
                    'js.features.admin.rents.rent_total_amount_cell_content.paid'
                  )}
                </span>
                <span className="font-medium">{` ${amountToString(
                  paidAmount
                )}`}</span>
              </li>
              <li className="text-text-failed">
                <span>
                  {t(
                    'js.features.admin.rents.rent_total_amount_cell_content.due'
                  )}
                </span>
                <span className="font-medium">{` ${amountToString(
                  unpaidAmount
                )}`}</span>
              </li>
            </ul>
          </div>
        </div>
      </PopOver.PopOverContent>
    </PopOver>
  );
};

export { RentTotalAmountCellContent };
