import React, { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { composeClassName } from '@/utils/util_helpers';
import { AvatarProps } from './types';
import {
  AvatarInitialsSizeTailWindMap,
  AvatarSizeMap,
  AvatarSizeTailWindMap,
  AvatarStatusIconSizeMap,
} from './styles';
import { ColocIcon } from '../icon';
import { getAvatarColor } from './helpers/get-avatar-color';

const Avatar: React.FC<AvatarProps> = ({
  src,
  alt = '',
  size = 'm',
  status = undefined,
  type = 'person',
  firstName = '',
  lastName = '',
}) => {
  const initialsToRender = useMemo(() => {
    const firstTrimmed = firstName.trim();
    const lastTrimmed = lastName.trim();

    if (firstTrimmed.length >= 1 && lastTrimmed.length >= 1) {
      return `${firstTrimmed.charAt(0)}${lastTrimmed.charAt(0)}`;
    }

    if (firstTrimmed.length >= 2) {
      return `${firstTrimmed.charAt(0)}${firstTrimmed.charAt(1)}`;
    }

    if (lastTrimmed.length >= 2) {
      return `${lastTrimmed.charAt(0)}${lastTrimmed.charAt(1)}`;
    }

    return '?';
  }, [firstName, lastName]);

  const iconPosition: 'top' | 'bottom' = useMemo(() => {
    switch (status) {
      case 'approved':
      case 'declined':
      case 'locked':
        return 'top';
      default:
        return 'bottom';
    }
  }, [status]);

  const avatarColor = useMemo(() => {
    return getAvatarColor({ text: initialsToRender, type });
  }, [initialsToRender, type]);

  return (
    <div className="relative inline-block">
      <div
        className={composeClassName(
          AvatarSizeTailWindMap[size],
          'flex items-center justify-center overflow-hidden rounded-full text-center',
          avatarColor
        )}
      >
        {type === 'person' ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {src && !src.includes('default-avatar') ? (
              <img
                src={src}
                alt={alt}
                width={AvatarSizeMap[size]}
                height={AvatarSizeMap[size]}
                className="h-full w-full object-cover"
              />
            ) : (
              <span
                className={composeClassName(
                  AvatarInitialsSizeTailWindMap[size],
                  'uppercase text-white'
                )}
              >
                {initialsToRender}
              </span>
            )}
          </>
        ) : (
          <ReactSVG
            src={require(`@assets/icons/other/coloc-avatar.svg`)}
            className={composeClassName(
              'inline-block  object-fill align-baseline'
            )}
            beforeInjection={(svg) => {
              svg.setAttribute('width', `${AvatarSizeMap[size]}`);
              svg.setAttribute('height', `${AvatarSizeMap[size]}`);
            }}
          />
        )}
      </div>
      {status && status !== 'none' && (
        <ColocIcon
          className={composeClassName(
            'absolute right-[0px]',
            iconPosition === 'top' ? 'top-[0px]' : 'bottom-[0px]'
          )}
          name={`status-${status}`}
          size={AvatarStatusIconSizeMap[size]}
        />
      )}
    </div>
  );
};

export { Avatar };
