import React from 'react';
import { PopOver } from '@/components/design-system/molecules/pop-over';
import { RoomAssignmentRoomTableCellProps } from './types';
import { CountryIcon, SocialIcon, ColocIcon } from '@/components/design-system/atoms/icon';
import { isCountryIconName } from '@/components/design-system/atoms/icon/country-icon';
import { CountryIconName } from '@/components/design-system/atoms/icon/country-icon/types';
import { Avatar } from '@/components/design-system/atoms/avatar';
import { theme } from '@/utils/util_theme';
import { intercomNewConversationUrl } from '@/utils/util_helpers/intercom';

const RoomNumber = ({
  room,
  checkout_tenant,
  base_url,
}: {
  room: RoomAssignmentRoomTableCellProps['room'];
  checkout_tenant: RoomAssignmentRoomTableCellProps['checkout_tenant'];
  base_url: RoomAssignmentRoomTableCellProps['base_url'];
}) => {
  if (checkout_tenant) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        className="underline"
        href={`${base_url}/admin/contracts/${checkout_tenant?.contract_id}`}
      >
        {checkout_tenant?.name
          ? `#${room.room_number} - ${checkout_tenant?.name}`
          : `#${room.room_number}`}
      </a>
    )
  }
  return `#${room.room_number}`;
};

const RoomAssignmentRoomCellContent: React.FC<RoomAssignmentRoomTableCellProps> =
  ({ room, checkout_tenant, base_url, new_room }) => {
    return (
      <PopOver placement="bottom-start">
        <PopOver.PopOverTrigger>
          <div className="flex flex-col flex-wrap overflow-hidden py-2 text-body font-normal">
            <span
              className="
                line-clamp-2 break-all font-medium text-text-primaryHeavy"
              style={{ whiteSpace: 'initial' }}
            >
              <RoomNumber
                room={room}
                checkout_tenant={checkout_tenant}
                base_url={base_url}
              />
            </span>
            <span
              className="line-clamp-1 break-all text-text-primaryLight"
              style={{ whiteSpace: 'initial' }}
            >
              {`${room.house.name}`}
            </span>
          </div>
        </PopOver.PopOverTrigger>
        <PopOver.PopOverContent className="room-popover">
          {checkout_tenant && (
            <Avatar src={checkout_tenant.avatar_url} type="person" />
          )}


          <div className="room-title">
            <span className="room--number">
              <RoomNumber
                room={room}
                checkout_tenant={checkout_tenant}
                base_url={base_url}
              />
            </span>
            <span className="house-number">{`${room.house.name}`}</span>
          </div>
          {checkout_tenant?.phone_number && (
            <div className="contact-info">
              <div className="contact-content">
                {isCountryIconName(
                  checkout_tenant?.phone_number.country_code ?? ''
                ) && (
                  <CountryIcon
                    className="pr-2"
                    name={
                      checkout_tenant?.phone_number
                        .country_code as CountryIconName
                    }
                  />
                )}
                <a
                  target="_blank"
                  href={intercomNewConversationUrl(
                    checkout_tenant?.intercom_user_id
                  )}
                  className="contact-text"
                  rel="noreferrer"
                >
                  {checkout_tenant?.phone_number.number}
                </a>
              </div>
              <div className="contact-actions">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://wa.me/${checkout_tenant?.phone_number.number}`}
                >
                  <SocialIcon
                    name="whatsapp"
                    size={20}
                    variant="black-white"
                    strokeColor={theme?.colors.J300}
                    hoverStrokeColor={theme?.colors.J200}
                  />
                </a>

                <button
                  onClick={() => {
                    navigator.clipboard.writeText(checkout_tenant?.phone_number.number);
                  }}
                >
                  <ColocIcon name="copy" />
                </button>
              </div>

            </div>
          )}

          {checkout_tenant?.email && (
            <div className="contact-info">
              <div className="contact-content">
                <ColocIcon name="envelope" />
                <a
                  target="_blank"
                  href={intercomNewConversationUrl(
                    checkout_tenant?.intercom_user_id
                  )}
                  className="contact-text"
                  rel="noreferrer"
                >
                  {checkout_tenant?.email}
                </a>
              </div>

              <div className="contact-actions">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(checkout_tenant?.phone_number.number);
                  }}
                >
                  <ColocIcon name="copy" />
                </button>
              </div>
            </div>
          )}
        </PopOver.PopOverContent>
      </PopOver>
    );
  };

export { RoomAssignmentRoomCellContent };
