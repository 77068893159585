import React from 'react';
import { OutgoingTransactionActionCellContentProps } from './types';
import { OutgoingTransactionSyncButton } from './components/outgoing-transaction-sync-button';

const OutgoingTransactionActionCellContent: React.FC<OutgoingTransactionActionCellContentProps> =
  ({ outgoingTransaction }) => {
    if (outgoingTransaction.status === 'pending') {
      return (
        <OutgoingTransactionSyncButton
          outgoingTransaction={outgoingTransaction}
        />
      );
    }
    return <></>;
  };

export { OutgoingTransactionActionCellContent };
