import React from 'react';
import { DropDown } from '@/components/design-system/molecules/dropdown';
import { SearchInput } from '@/components/design-system/molecules/search-input';

const StatusList = ['applied', 'accepted', 'queued', 'cancelled'];

const ApplicantFilters = ({ value, onChange }: { value: { search: string, status: string }; onChange: void }) => {
  return (
    <div className="relative flex w-full items-end justify-between">
      <div className="flex flex-wrap gap-4">
        <SearchInput
          placeholder="Search"
          className="w-[250px]"
          label="Search"
          value={value.search}
          onChange={(e) => onChange({ ...value, search: e.target.value })}
        />

        <DropDown
          placeholder="Status"
          className="w-[180px]"
          showLabel
          label="Status"
          value={value.status}
          onChange={(status) => onChange({ ...value, status })}
        >
          <DropDown.DropDownOptions>
            {StatusList.map((status) => {
              return (
                <DropDown.DropDownOption key={status} value={status}>
                  <DropDown.DropDownOptionDefaultContent>
                    {status}
                  </DropDown.DropDownOptionDefaultContent>
                </DropDown.DropDownOption>
              );
            })}
          </DropDown.DropDownOptions>
        </DropDown>

      </div>
    </div>
  );
};

export { ApplicantFilters };
